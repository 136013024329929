<template>
  <v-app>
    <v-main v-if="!loggedIn()">
      <router-view />
    </v-main>
    <div class="mainDiv" v-if="loggedIn()">
      <div class="sideBarDiv">
        <v-container>
          <v-row
            v-on:click="this.logUserOut"
            class="logout"
            style="padding-bottom: 0 !important"
          >
            <v-col cols="2" style="padding-bottom: 0 !important">
              <v-icon color="#FE000C" medium style="padding-top: 0 !important"
                >mdi-logout</v-icon
              >
            </v-col>
            <v-col
              cols="10"
              style="
                text-align: left;
                padding-bottom: 0;
                padding-left: 0;
                font-weight: bold;
                font-size: large;
                color: #fe000c;
              "
              >Logout</v-col
            >
          </v-row>
          <v-row style="font-weight: bold; font-size: x-large">
            <v-col class="userNameDiv">
                <h3 class="userName"> Hello, {{ getUserName() }}</h3>
            </v-col>
          </v-row>
          <v-row class="sidebarOptionsMain">
            <v-col>
              <v-row
                v-for="(option, key) in sideOptions"
                :key="key"
                @click="reDirect(option)"
                class="sidebarOptions"
                v-on:click="changeActiveItem(key)"
                :class="{ active: key == activeMenuItem }"
                style="color: #5a5a5a"
              >
                <v-col cols="3"
                  ><img class="oee" src="./assets/userInputBlack.svg"
                /></v-col>
                <v-col
                  cols="9"
                  style="font-size: 14px; padding: 20px"
                  v-bind:option="option"
                  >{{ option.display_name }}</v-col
                >
              </v-row>
            </v-col>
          </v-row>

          <v-row class="logo">
            <v-col>
              <v-row>
                <v-col cols="3"
                  ><img class="sidebarLogo" src="./assets/escape_logo.png" width="200px"
                /></v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="contentDiv">
        <v-main>
          <router-view />
        </v-main>
      </div>
    </div>
  </v-app>
</template>

<script>
import router from "./router";
import jwt from "jwt-decode";
export default {
  name: "App",
  //   components:{
  // Login:Login
  //   },

  data: () => {
    return {
      sideOptions: [],
      activeMenuItem: sessionStorage.getItem("activeMenuItem") || 0,
    };
  },
  methods: {
    getUserName() {
      let storage = sessionStorage.getItem("jwt");
      let self = this;

      if (storage) {
        if (!this.optionsReceived) {
          self.getOptions();
        }
        return jwt(storage).name.charAt(0).toUpperCase()+jwt(storage).name.slice(1);
      } else {
        return null;
      }
    },
    loggedIn() {
      let storage = sessionStorage.getItem("jwt");
      if (storage) {
        return true;
      } else {
        return false;
      }
    },
    logUserOut() {
      this.optionsReceived = false;
      sessionStorage.clear();
      router.push("/login").catch(()=>{});;
    },
    reDirect(option) {
      console.log("the reddy route is: ",option.route_path)
      router.push(option.route_path).catch(()=>{});;
    },
    getOptions() {
      let token = sessionStorage.getItem("jwt");
      let decodedToken;
      if (token) {
        decodedToken = jwt(token);
      }
      if (decodedToken && decodedToken.role === "admin") {
        this.sideOptions = [
          {
            display_name: "Executives",
            route_path: "listExecutives",
          },
          {
            display_name: "Rate Cards",
            route_path: "listRateCards",
          },
        ];
      } else if (decodedToken && decodedToken.role === "executive") {
        this.sideOptions = [
          {
            display_name: "Agencies",
            route_path: "listAgencies",
          },
          {
            display_name: "Generate Bill",
            route_path: "upload",
          },
        ];
      }
      this.optionsReceived = true;
      router.push(this.sideOptions[0].route_path).catch(()=>{});
    },
    changeActiveItem(key) {
      sessionStorage.setItem("activeMenuItem", key);
      this.activeMenuItem = key;
    },
  },
  mounted: function () {
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = "auto";
  },
  destroyed: function () {
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = null;
  },
};
</script>
<style>
#navbar {
  z-index: 100;
}

* {
  font-family: "EMprint";
}
.oee{
  /* height: 100px;
  
  align-items: center;
 */
  width: 40px;
  /* margin-right: 40px; */
    object-fit: contain;
}
.mainDiv {
  display: flex;
  margin: 0;
  height: 100%;
  overflow: hidden;
}
.sideBarDiv {
  width: 18%;
  position: fixed;
  overflow: hidden;
  /* background-color: #263f56; */
  background-color: #f2f6fa;
  height: 100vh;
}
.contentDiv {
  width: 82%;
  /* border: solid; */
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  position: fixed;
  /* background-color: #f0f0f0; */
  right: 0;
}
.logo {
  position: absolute;
  bottom: 0px;
  background-color: #263f56;
  width: 100%;
}
.sidebarLogo{
  width: 180px;
  object-fit: contain;
}
.logout {
  cursor: pointer;
}
.sidebarOptionsMain {
  /* background: #e3e2e2 !important; */
  overflow-y: auto;
  height: 55vh;
}
.sidebarOptions {
  cursor: pointer;
  display: flex;
  justify-content: space-evenly !important;
}
.active {
  color: #ee4036 !important;
  font-weight: bold;
}
.organizationName {
  padding-bottom: 0 !important;
  font-weight: bolder;
  font-size: x-large;
  color: #004d90;
}
.userName {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.userNameDiv{
  display: flex;
}
</style>