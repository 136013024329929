import Vue from 'vue';
import Router from 'vue-router';
import jwt from 'jwt-decode';
// import Home from "./views/Home.vue";/

let token = sessionStorage.getItem('jwt');

Vue.use(Router);

let router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
      path: "/",
      name: "loading",
      component: () => import("./components/loading.vue"),
      meta: {
        requiresAuth: true,
      },
    },
		{
			path: '/login',
			name: 'login',
			component: () => import('./components/login.vue')
		},
		{
			path: '/listExecutives',
			name: 'customerFeatures',
			component: () => import('./views/executives.vue'),
			meta: {
				requiresAuth: true,
				isAdmin: true
			}
		},
		{
			path: '/listRateCards',
			name: 'rateCards',
			component: () => import('./views/rateCards.vue'),
			meta: {
				requiresAuth: true,
				isAdmin: true
			}
		},
		{
			path: '/listAgencies',
			name: 'agencies',
			component: () => import('./views/agencies.vue'),
			meta: {
				requiresAuth: true,
				isExecutive: true
			}
		},
		{
			path: '/upload',
      name: 'upload',
      component: () => import('./components/upload.vue'),
			meta: {
				requiresAuth: true,
				isExecutive: true
			}
		},
		{
			path: '/**',
			name: 'NotFound',
			component: () => import('./views/NotFound.vue')
		}
	]
});

router.beforeEach((to, from, next) => {
  console.log("router hit", to)
	let decodedToken;
	if (token) {
		decodedToken = jwt(token);
	}
	console.log('here is the full path', to);
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (sessionStorage.getItem('jwt') == null) {
			next({
				path: '/login',
				params: { nextUrl: to.fullPath }
			});
		} else {
			if (to.matched.some((record) => record.meta.isAdmin)) {
				if (decodedToken && decodedToken.role !== 'admin') {
					next({
						path: '/**',
						params: { nextUrl: to.fullPath }
					});
				} else {
						next();
				}
			} else if (to.matched.some((record) => record.meta.isExecutive)) {
				if (decodedToken && decodedToken.role !== 'executive') {
					next({
						path: '/**',
						params: { nextUrl: to.fullPath }
					});
				} else {
						next();
				}
			}
		}
	} else {
		next();
	}
});

export default router;
